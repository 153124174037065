<template>
	<div class="downLoad"> 
		<div class="mian">
			<div class="inner_nav clearfix">

				<div class="fl inner_nav_l">当前位置：<span @click="backFun(1)">首页</span> > <span
						@click="backFun(2)">字体素材</span> > 下载清单</div>
			</div>
			<router-link class="inner_banner" :to="{name:banner.url,query:{id:banner.links_id!=0?banner.links_id:''} }">
				<img :src="banner.thumb" alt="" />
			</router-link>
			<div class="downLoad_list">
				<template v-for="(item,index) in list">
					<div class="downLoad_li" :key="index" v-if="item.list.length>0">
						<div class="downLoad_li_head">
							<div class="downLoad_li_head_l">
								<div class="downLoad_li_check" :class="item.check?'active':''"
									@click="checkOneFun(index)"></div>
								<div class="downLoad_li_head_name">{{item.name}}</div>
							</div>
							<div class="downLoad_li_head_r">操作</div>
						</div>
						<div>
							<div class="downLoad_li_item" v-for="(items,indexs) in item.list" :key="indexs">
								<div class="downLoad_li_check " :class="items.check?'active':''"
									@click="checkTwoFun(index,indexs)"></div>
								<div class="downLoad_li_item_l">
									<div class="downLoad_li_item_l_img">
										<img :src="items.material.thumb" alt="">
									</div>
									<div class="downLoad_li_item_l_info">
										<div class="downLoad_li_item_info_name">标题：{{items.material.title}}</div>
										<p v-if="items.material.ml_cate">素材分类：{{items.material.ml_cate}}</p>
										<p v-if="items.material.format">素材格式：{{items.material.format}}</p>
									</div>
								</div>
								<div class="downLoad_li_item_r" @click="delFun(items.id)"></div>
							</div>
						</div>
					</div>
				</template>
				<el-empty v-if="total == 0" style="margin-bottom:100px" description="暂无数据"></el-empty>
			</div>
			<div class="downLoad_allCheck">
				<div class="downLoad_allCheck_l">
					<div class="downLoad_li_check" :class="checkAll?'active':''" @click="checkAllFun()"></div>
					<div class="downLoad_allCheck_name">全选</div>
					<p>共{{total}}件商品，已选择 <span>{{checkNum}}</span> 件</p>
				</div>
				<div class="downLoad_allCheck_r">
					<div class="downLoad_allCheck_download" @click="delAllFun">
						批量删除 ({{checkNum}})
					</div>
					<div class="downLoad_allCheck_download" @click="downZipFun" v-loading.fullscreen.lock="loading">
						打包下载 ({{checkNum}})
					</div>
				</div>

			</div>
		</div>
		<!-- 侧边导航 -->
		<sidebar ref="downtotal"></sidebar>
		<successPop v-if="randomState" ref="success" type="baodian2" :showarr="list_down"></successPop>

	</div>
</template>

<script>
	// import { WOW } from 'wowjs'
	import sidebar from '../components/sidebar.vue'
	import successPop from "../components/successPop.vue";
	export default {
		name: "downLoad",
		components: {
			sidebar,
			successPop
		},
		data() {
			return {
				banner: {}, //banner
				list: [],
				total: 0, //总数
				checkNum: 0, //选中数量
				checkAll: false, //是否全选
				loading:false,
				ids: [],
				randomState:false,
				list_down:[
					false,
					false
				]
			}
		},
		mounted() {
			this.$parent.routerIndex = 0;
			this.$parent.showIndex = 1;
			// new WOW().init();
			this.listFun();
			this.$refs.downtotal.listFun();

			this.bannerOneban();
		},
		methods: {
			// 获取内部banner、
			bannerOneban() {
				var that = this;
				this.$api.POST(this.$face.bannerOneban, {
					location: 13
				}, function(res) {
					switch (res.data.links) {
						case 0:
							res.data.url = '';
							break;
						case 1:
							res.data.url = 'material';
							break;
						case 2:
							res.data.url = 'video';
							break;
						case 3:
							res.data.url = 'deformation';
							break;
						case 4:
							res.data.url = 'ranking';
							break;
						case 5:
							res.data.url = 'dailyWord';
							break;
						case 6:
							res.data.url = 'fontSearch';
							break;
						case 7:
							res.data.url = 'dailyWordUpload';
							break;
						case 8:
							res.data.url = 'vip';
							break;
						case 9:
							res.data.url = 'project';
							break;
						case 10:
							res.data.url = 'rankingDetail';
							break;
						case 11:
							res.data.url = 'projectDetail';
							break;
						case 12:
							res.data.url = 'downLoad';
							break;
					}
					that.banner = res.data
				})
			},

			listFun() {
				var that = this;
				this.$api.POST(this.$face.download_cartIndex, '', function(res) {
					res.data.list.forEach(element => {
						element.check = false;
						if (element.list.length > 0) {
							[
								element.list.forEach(item => {
									item.check = false;
								})
							]
						}
					});
					that.list = res.data.list;
					that.total = res.data.total;

					localStorage.setItem('total', res.data.total);
				})
			},

			// 移除清单
			delFun(id) {
				var that = this;
				this.$api.POST(this.$face.download_cartDel, {
					id: id
				}, function(res) {
					that.$utile.prompt('success', '移出清单成功');
					that.listFun();
					that.$refs.downtotal.listFun();
					that.checkNum = 0;
				})
			},
			// 移除清单
			delAllFun() {
				var that = this;
				this.$api.POST(this.$face.download_cartDel, {
					id: this.ids.join(',')
				}, function(res) {
					that.$utile.prompt('success', '移出清单成功');
					that.listFun();
					that.$refs.downtotal.listFun();
					that.checkNum = 0;
				})
			},

			checkOneFun(index) {
				this.list[index].check = !this.list[index].check;
				if (this.list[index].list.length > 0) {
					this.list[index].list.forEach(item => {
						item.check = this.list[index].check
					})
				}

				this.sumFun()
			},
			checkTwoFun(index, indexs) {
				this.list[index].list[indexs].check = !this.list[index].list[indexs].check;

				this.sumFun()
			},
			checkAllFun() {
				this.checkAll = !this.checkAll;
				this.list.forEach(item => {
					item.check = this.checkAll;
					if (item.list.length > 0) {
						item.list.forEach(el => {
							el.check = this.checkAll
						})
					}
				})
				this.sumFun()
			},

			// 计算选中数量
			sumFun() {
				var s = 0;
				var array = [];
				this.list.forEach(item => {
					if (item.list.length > 0) {
						item.list.forEach(ele => {
							if (ele.check == true) {
								s++;
								array.push(ele.id)
							}
						})
					}
				})
				this.checkNum = s;
				this.ids = array
			},
			// 打包下载
			downZipFun() {
				this.list_down=[false,false];
				for(let value of this.list){
					for(let o of value.list){
						if(o.check==true){
							this.list_down[value.id-1]=true;
							break;
						}
					}
				}
				//this.randomState=true;
				this.loading=true;
				var that = this;
				var params = {
					cart_id: this.ids.join(','),
				}
				this.$api.POST500(this.$face.downloadZipdown, params, function(res) {
					if(res.code == 200){
						const a = document.createElement('a')
						//   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
						a.href = res.data.down_url // 完整的url则直接使用
						var name = res.data.down_url.split('/')
						console.log(name[name.length-1])
						a.download = name[name.length-1];
						// document.body.appendChild(a)
						a.click()
						that.loading=false;
						//that.$utile.prompt('success', res.msg);
						that.listFun()
						that.$refs.downtotal.listFun();
						setTimeout(()=>{
							that.randomState=true;
						},1000)
					}else{
						console.log(1111)
						that.$utile.prompt('error',res.msg);
						that.loading=false;
					}
				})
			},

			backFun(e) {
				localStorage.setItem('clearAll', 1)
				if (e == 1) {
					this.$router.push({
						name: 'refresh',
						query: {
							usernext: '/'
						}
					})
				} else {
					this.$router.push({
						name: 'refresh',
						query: {
							usernext: 'material'
						}
					})
				}
			},
		},
		watch: {

		},
		activated() {
			this.$nextTick(() => {
				this.$parent.routerIndex = 0;
				this.$parent.showIndex = 1;
				this.checkNum = 0; //选中数量
				this.checkAll = false; //是否全选
				localStorage.removeItem('antString')
				localStorage.removeItem('clearAll')
				this.listFun();
				this.$refs.downtotal.listFun()
			});
		}
	};
</script>

<style scoped>
 body {
    margin: 0;
  }
	.downLoad {
		background-color: #f6f6f6;
	}

	.inner_nav {
		padding: 27px 0 17px;
	}

	.inner_nav_l {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
	}

	.inner_banner {
		display: block;
		margin-bottom: 24px;
	}

	.inner_banner img {
		width: 100%;
	}


	.downLoad_li {
		width: 100%;
		height: auto;
		background-color: #ffffff;
		border-radius: 10px;
		padding: 5px 28px;
		box-sizing: border-box;
		margin-bottom: 28px;
	}

	.downLoad_li:last-child {
		margin-bottom: 21px;
	}

	.downLoad_li_head {
		padding: 0 85px 0 23px;
		box-sizing: border-box;
		border-bottom: 1px solid #eee;
		height: 75px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.downLoad_li_check {
		width: 18px;
		height: 18px;
		background-color: #ffffff;
		border-radius: 3px;
		border: solid 1px #cccccc;
		box-sizing: border-box;
		margin-right: 23px;
		cursor: pointer;
	}

	.downLoad_li_check.active {
		background-color: #5957FF;
		border-color: #5957FF;
		background-image: url(../assets/images/check.png);
		background-repeat: no-repeat;
		background-size: 11px 14px;
		background-position: 2px 1px;
	}

	.downLoad_li_head_l {
		display: flex;
		align-items: center;
	}

	.downLoad_li_head_name {
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #333333;
	}

	.downLoad_li_head_r {
		font-size: 16px;
		color: #333333;
		width: 40px;
		text-align: center;
	}


	.downLoad_li_item {
		display: flex;
		align-items: center;
		padding: 20px 85px 21px 23px;
		border-bottom: solid 1px #eee;
		box-sizing: border-box;
	}

	.downLoad_li_item:last-child {
		border: none;
	}

	.downLoad_li_item_l {
		width: 1154px;
		display: flex;
	}

	.downLoad_li_item_l_img {
		width: 174px;
		height: 115px;
		border-radius: 10px;
		overflow: hidden;
		margin-right: 17px;
		position: relative; 
	}

	.downLoad_li_item_l_img img {
	
		position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    height: auto;
	}

	.downLoad_li_item_l_info {
		width: 900px;
	}

	.downLoad_li_item_l_info div {
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #333333;
		margin-top: 10px;
		margin-bottom: 14px;
	}

	.downLoad_li_item_l_info p {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 24px;
		letter-spacing: 0px;
		color: #999999;
	}

	.downLoad_li_item_r {
		width: 40px;
		height: 40px;
		background-color: #ffffff;
		border-radius: 5px;
		border: solid 1px #eeeeee;
		background-image: url(../assets/images/del.png);
		background-repeat: no-repeat;
		background-position: center center;
		cursor: pointer;
	}

	.downLoad_li_item_r:hover {
		background-color: #f6f6f6;
	}


	.downLoad_allCheck {
		width: 100%;
		height: 92px;
		background-color: #ffffff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 29px 0 51px;
		box-sizing: border-box;
		margin-bottom: 69px;
	}

	.downLoad_allCheck_l {
		display: flex;
		align-items: center;
	}

	.downLoad_allCheck .downLoad_li_check {
		margin-right: 9px;
	}

	.downLoad_allCheck_name {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 32px;
		letter-spacing: 0px;
		color: #333333;
		margin-right: 30px;
	}

	.downLoad_allCheck_l p {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 32px;
		letter-spacing: 0px;
		color: #333333;
	}

	.downLoad_allCheck_l p span {
		font-weight: bold;
		color: #5957FF;
	}

	.downLoad_allCheck_r {
		display: flex;
		align-items: center;
	}

	.downLoad_allCheck_r>div:first-child {
		margin-right: 20px;
	}

	.downLoad_allCheck_download {
		width: 145px;
		height: 50px;
		line-height: 50px;
		background-color: #5957FF;
		border-radius: 10px;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #FFF;
		text-align: center;
		cursor: pointer;
	}




	.sidebar_r {
		right: 2%;
		left: auto;
	}
</style>
